import React, { useRef } from "react";
import { recipes as data } from "../data/recipes";
import "./recipe.scss";
import Layout from "../components/Layout";
import { Button, Row } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

const Recipe = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  const componentRef = useRef();

  const printRecipe = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "recipe",
    copyStyles: true,
  });

  return (
    <Layout>
      <article ref={componentRef}>
        <section className="recipe-header">
          <div className="recipe-header--recipe-image-container">
            <GatsbyImage
              loading="lazy"
              image={getImage(props.data.dataJson.recipes[i].recipeImage)}
              className="recipe-header--recipe-image-container--recipe-image"
              alt={props.data.dataJson.recipes[i].recipeImageAlt}
            />
          </div>
          <div className={"recipe-header--recipe-description"}>
            <div className={"recipe-header--recipe-description--recipe-title"}>
              <h1>
                <figure>
                  <img
                    src={data[i].recipeTitleImage}
                    alt={data[i].recipeTitleImageAlt}
                  />
                </figure>
              </h1>
            </div>
            {data[i].featured ? <p>{data[i].description}</p> : null}
          </div>
          <div
            className={
              !data[i].featured
                ? "recipe-header--recipe-description-non-featured non-featured-description-height"
                : "recipe-header--recipe-description-non-featured"
            }
          >
            {!data[i].featured ? (
              <GatsbyImage
                loading="lazy"
                image={getImage(props.data.dataJson.recipes[i].recipeImage)}
                alt={props.data.dataJson.recipes[i].recipeImageAlt}
              />
            ) : null}
            <div
              className={
                !data[i].featured
                  ? "recipe-header--recipe-description-non-featured--recipe-title-non-featured"
                  : "recipe-header--recipe-description-non-featured--featured"
              }
            >
              <figure>
                <img
                  src={data[i].recipeTitleImage}
                  alt={data[i].recipeTitleImageAlt}
                />
              </figure>
            </div>
            {data[i].featured ? <p>{data[i].description}</p> : null}
          </div>
          {data[i].featured ? (
            <figure>
              <GatsbyImage
                loading="lazy"
                className="recipe-header--recipe-featured-image"
                image={getImage(props.data.allFile.edges[1].node)}
                alt="Featured Recipe"
              />
            </figure>
          ) : null}
        </section>
        <section
          className={
            data[i].featured ? "recipe-hero" : "recipe-hero--display-none"
          }
        >
          <GatsbyImage
            loading="lazy"
            image={getImage(props.data.dataJson.recipes[i].recipeImage)}
            alt={props.data.dataJson.recipes[i].recipeImageAlt}
          />
        </section>
        <section className="recipe-content">
          <GatsbyImage
            loading="lazy"
            className="recipe-content--background"
            image={getImage(props.data.allFile.edges[0].node)}
            alt="Background"
          />
          <Row md={2} xs={1} className="row">
            <div className="recipe-content--recipe-column">
              <h2>What you Need</h2>
              <dl>
                {data[i].recipeIngredients.map((item, i) => (
                  <>
                    <dt>{item.quantity}</dt>
                    <dd>{item.ingredient}</dd>
                  </>
                ))}
              </dl>
            </div>
            <div className="recipe-content--recipe-column">
              <h2>How To Make It</h2>
              <dl>
                {data[i].recipeSteps.map((item, i) => (
                  <>
                    <dt>{item.step}</dt>
                    <dd>{item.instruction}</dd>
                  </>
                ))}
              </dl>
              <Button
                variant="outline-danger"
                className="recipe-content--recipe-column--button"
                onClick={printRecipe}
              >
                Print this Recipe
              </Button>
            </div>
          </Row>
        </section>
      </article>
    </Layout>
  );
};

export default Recipe;

export const images = graphql`
  query {
    allFile(
      filter: { relativePath: { in: ["fade-1.jpg", "featured.png"] } }
      sort: { order: ASC, fields: relativePath }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: WEBP
              webpOptions: { quality: 100 }
              quality: 100
            )
          }
        }
      }
    }
    dataJson {
      id
      recipes {
        id
        recipeImage {
          id
          base
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: WEBP
              webpOptions: { quality: 100 }
              quality: 100
            )
          }
        }
        recipeImageAlt
      }
    }
  }
`;
